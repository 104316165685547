<template>
  <div>
    <!-- Início filtro -->
    <v-dialog
      v-model="modal.filtro"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-app-bar-nav-icon @click="modal.filtro = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
          <v-icon class="ml-2"> fas fa-user </v-icon>
          <v-toolbar-title class="pl-4"> Filtrar usuários </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="limparFiltros">
            <v-icon>fas fa-broom</v-icon>
          </v-app-bar-nav-icon>
          <v-app-bar-nav-icon
            @click="carregarUsuarios(), (modal.filtro = false)"
          >
            <v-icon>fas fa-search</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>
          <v-col class="pt-6">
            <v-form>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-fingerprint"
                    label="Código"
                    type="number"
                    autocomplete="off"
                    v-model="filtros.codigo"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="fas fa-user"
                    label="Usuário"
                    type="text"
                    autocomplete="off"
                    v-model="filtros.usuario"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    outlined
                    prepend-icon="far fa-envelope"
                    label="E-mail"
                    type="text"
                    autocomplete="off"
                    v-model="filtros.email"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim filtro -->

    <!-- Inicio Modal Add-->
    <v-row class="px-9 py-7" justify="end">
      <v-dialog v-model="dialogAddUsers" max-width="900">
        <v-card>
          <v-container>
            <v-card-title class="text-h5" style="justify-content:center">
              Adicionar - Usuário
            </v-card-title>
            <v-row>
              <v-col cols="8" sm="8" md="12">
                <v-card-title class="text-h7" style="padding-top: 0"> Email </v-card-title>
                <v-card-text style="padding-bottom: 0">
                  <v-text-field
                    autoComplete="new-password"
                    :name="Math.random()"
                    browser-autocomplete="new-password"
                    placeholder="Informe o e-mail"
                    ref="inputRef"
                    solo
                    type="text"
                    :rules="[() => !!newUsers.new_email || 'Campo obrigatório']"
                    v-model="newUsers.new_email"
                  >
                    </v-text-field>
                </v-card-text>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" sm="8" md="6">
                <v-card-title class="text-h7" style="padding-top: 0"> Senha </v-card-title>
                <v-card-text style="padding-bottom: 0">
                  <v-text-field
                    autoComplete="new-password"
                    :name="Math.random()"
                    browser-autocomplete="new-password"
                    placeholder="Informe a senha"
                    solo
                    :rules="[() => !!new_senha || 'Campo obrigatório']"
                    v-model="newUsers.new_senha"
                    :append-icon="show1 ? 'far fa-eye' : 'far fa-eye-slash'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                  >
                  </v-text-field>
                </v-card-text>
              </v-col>

              <v-col cols="6" sm="8" md="6">
                <v-card-title class="text-h7" style="padding-top: 0"> Confirmar Senha </v-card-title>
                <v-card-text style="padding-bottom: 0">
                  <v-text-field
                    :autocomplete="null"
                    :name="Math.random()"
                    browser-autocomplete="new-password"
                    placeholder="Confirmar a senha"
                    ref="inputRef"
                    solo
                    :rules="[
                      () => !!newUsers.confirmacao || 'Campo obrigatório!!!',
                      () => newUsers.confirmacao == newUsers.new_senha || 'Senhas devem ser iguais!!!',
                    ]"
                    v-model="newUsers.confirmacao"
                    :append-icon="show2 ? 'far fa-eye' : 'far fa-eye-slash'"
                    :type="show2 ? 'text' : 'password'"
                    @click:append="show2 = !show2"
                  >
                  </v-text-field>
                </v-card-text>
              </v-col>

            </v-row>

          </v-container>

          <v-card-actions class="alignmentDialog">
            <v-btn class="ma-2" color="error" @click="dialogAddUsers = false">
              Cancelar
            </v-btn>
            <v-btn class="ma-2" color="primary" @click="SendNewUsers()">
              Salvar
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>
    <!-- Fim Modal Add-->

    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon> fas fa-user </v-icon>
        <v-toolbar-title class="pl-4"> Usuários </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-app-bar-nav-icon @click="newUsers" v-if="this.all_Clean == true" > -->
          <v-btn color="primary" v-if="this.all_Clean == true" @click="(dialogAddUsers = true)"> Adicionar </v-btn>
          <!-- <v-icon>fas fa-upload</v-icon> -->
        <!-- </v-app-bar-nav-icon> -->
        <v-app-bar-nav-icon v-if="this.all_Clean == true" @click="modal.filtro = true">
          <v-icon>fas fa-search</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table
              dense
              :headers="grid.usuarios.cabecalho"
              :items="grid.usuarios.items"
              :items-per-page="grid.usuarios.paginacao"
              hide-default-footer
              class="elevation-1 pt-4"
              :loading="grid.usuarios.carregando"
            >
              <template #item.operacoes="{ item }">
                <div class="btnOperacoes">
                  <v-icon size="12" color="green" class="operacoes" @click="editUsers(item)" >
                    fas fa-pen
                  </v-icon>
                </div>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim principal -->
  </div>
</template>

<script>
import { baseApiUrl } from "@/global";
import axios from "axios";

export default {
  name: "Empresas",
  data: () => ({
    codigo_empresa: Number(window.localStorage.getItem("factory")),
    all_Clean: (localStorage.getItem("actRead") == "true"),

    grid: {
      usuarios: {
        cabecalho: [
          { text: "#", value: "codigo", sortable: false },
          { text: "Usuário", value: "usuario" },
          { text: "E-mail", value: "email" },
          { text: "", value: "operacoes", sortable: false },
        ],
        items: [],
        carregando: false,
      },
    },
    modal: {
      filtro: false,
    },
    filtros: {
      codigo: "",
      usuario: "",
      email: "",
    },
    newUsers: {
      usuario: "",
      new_email: "",
      new_senha: "",
      confirmacao: "",
    },
    dialogAddUsers: false,
    show1: false,
    show2: false,
  }),
  mounted() {
    this.carregarUsuarios();
  },
  methods: {
    limparFiltros() {
      this.filtros.codigo = "";
      this.filtros.usuario = "";
      this.filtros.email = "";
    },
    fechar() {
      this.$router.push("/");
    },
    async SendNewUsers() {
      if ( (this.newUsers.new_email.length == 0 && this.newUsers.new_senha.length == 0 && this.newUsers.confirmacao.length == 0) ||
           (this.newUsers.confirmacao.trim !== this.newUsers.new_senha.trim) ) {
        this.$swal({
          title: 'Ops!!!',
          text: 'OS campos devem ser preenchidos!!!',
          icon: 'warning',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#4BB543',
          allowOutsideClick: true,
        })
        return
      }else{
        await axios
        .post(`${baseApiUrl}/usuarios`, {
          cod_empresa: this.codigo_empresa,
          usuario: "USER",
          email: this.newUsers.new_email,
          senha: this.newUsers.new_senha,
          confirmacaoSenha: this.newUsers.confirmacao,
        })
        .then((response) => {
          this.$swal({
            title: 'Sucesso!!!',
            text: 'Usuário adicionado com sucesso.',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .catch((error) => {
          this.$swal({
            title: 'Que pena!!!',
            text: 'Não foi possível concluir!!!',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .finally(() => {
          this.dialogAddUsers = false;
          this.carregarUsuarios();
        });
      }
    },
    editUsers(value) {
      this.$swal({
        title: 'Que pena!!!',
        text: 'Em desenvolvimento, aguarde...',
        icon: 'warning',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#4BB543',
        allowOutsideClick: true,
      });
    },
    carregarUsuarios() {
      this.grid.usuarios.carregando = true;
      let url = `${baseApiUrl}/usuarios`;

      url += `?cod_empresa=${this.codigo_empresa}`;
      if (this.all_Clean == false) {
        url += "&filtroPassaBaixa=PassaAlta";
      }
      if (this.filtros.codigo != "") {
        url += `&codigo=${this.filtros.codigo}`;
      }
      if (this.filtros.usuario != "") {
        url += `&usuario=${this.filtros.usuario}`;
      }
      if (this.filtros.email != "") {
        url += `&email=${this.filtros.email}`;
      }

      axios
      .get(url)
      .then((res) => {
        this.grid.usuarios.items = res.data.usuarios;
      })
      .finally(() => {
        this.grid.usuarios.carregando = false;
      });
    },
  },
};
</script>